import React, { useEffect, useState } from 'react';
import { useAxios } from 'store/axios';

import { Loading } from '../../components/Loading';
import { DataError } from '../../components/DataError';
import { InputLabel } from '../../components/InputLabel';
import { Select } from '../../components/Select';
import { FileInput } from '../../components/FileInput';
import { Button } from '../../components/Button';
import { FormError } from './FormError';
import { Attachment } from './Attachment';
import s from './MainPage.module.scss';

export interface IOrganization {
  id: string;
  name: string;
}

export function MainPage() {
  const axios = useAxios();
  const [pageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [org, setOrg] = useState<string | null>(null);

  const [data, setData] = useState<IOrganization[]>([]);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    axios
      .get('/client/orgStructure/myOrgUnits')
      .then((res) => {
        setPageLoading(false);
        setData(res.data);
      })
      .catch((err) => setPageError(String(err?.message)));
  }, [axios]);

  if (pageLoading) {
    return <Loading />;
  } else if (!!pageError || !data || !data.length) {
    return <DataError />;
  } else if (success) {
    return (
      <main className={s.MainPage__card}>
        <h2 className={s.MainPage__title}>Загрузка файла</h2>
        <p className={s.MainPage__success}>Файл успешно загружен.</p>
      </main>
    );
  } else {
    return (
      <main className={s.MainPage__card}>
        <h2 className={s.MainPage__title}>Загрузка файла</h2>
        <InputLabel title={'Организация'}>
          <Select
            placeholder={'Выберите организацию из списка'}
            disabled={!data.length}
            options={data.map(({ id, name }) => ({ label: name, value: id }))}
            value={org}
            onChange={setOrg}
          />
        </InputLabel>

        {file && <Attachment file={file} />}

        <FileInput
          description={`Загрузите XLSX-файл объемом не более 100 MB.`}
          onChange={(files) => setFile(files[0] ?? null)}
        />

        <Button
          style={{ alignSelf: 'flex-end', minWidth: 114 }}
          loading={uploading}
          disabled={!org || !file}
          onClick={() => {
            if (!!file && !!org) {
              setUploading(true);
              setUploadError(null);
              const form = new FormData();
              form.append('file', file);
              axios
                .post(`/client/orgStructure/uploadOrgStructure?org=${org}`, form)
                .then(() => {
                  setSuccess(true);
                })
                .catch((err) => {
                  console.error(err);
                  setUploading(false);
                  setUploadError(err?.response?.data?.message);
                });
            }
          }}>
          Отправить
        </Button>

        {!!uploadError && <FormError message={uploadError} />}
      </main>
    );
  }
}

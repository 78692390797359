import React from 'react';

import { FileIcon, FileIconSize } from 'components/FileIcon';
import s from './Attachment.module.scss';

export function Attachment({ file }: { file: File }) {
  return (
    <div className={s.Attachment}>
      <FileIcon fileName={file.name} fileIconSize={FileIconSize.M} />

      <div className={s.Attachment__meta}>
        <div className={s.Attachment__name}>{file.name}</div>
        <div className={s.Attachment__fileSize}>
          {file.size < 1000000 ? '< 1 MB' : `${Math.round(file.size / 1000000)} MB`}
        </div>
      </div>
    </div>
  );
}

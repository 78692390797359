import React from 'react';
import s from './InputLabel.module.scss';

export function InputLabel({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <div className={s.InputLabel__wrapper}>
      <label className={s.InputLabel}>{title}</label>
      {children}
    </div>
  );
}

import React from 'react';
import { FileIconMapper } from './FileIconMapper';

export enum FileIconSize {
  S = 'S',
  M = 'M'
}

export interface FileIconProps {
  fileName?: string;
  fileIconSize?: FileIconSize;
}

export function FileIcon({ fileName, fileIconSize }: FileIconProps) {
  const fileNameArr = fileName?.toLocaleLowerCase().split('.');
  const fileFormat = fileNameArr?.[fileNameArr.length - 1];
  const fileIcon = fileFormat || 'unknown';
  const Icon = fileIconSize === 'M' ? FileIconMapper[fileIcon].M : FileIconMapper[fileIcon].S;
  return <Icon />;
}

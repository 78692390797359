import React, { useLayoutEffect } from 'react';
import { AxiosRequestHeaders } from 'axios';
import { useKeycloak } from 'store/keycloak';
import { AxiosContext } from './AxiosContext';
import { axiosInstance } from './axiosInstance';

export function AxiosProvider({ children }: { children: React.ReactNode }) {
  const { client: keycloak, isLoggedIn } = useKeycloak();

  useLayoutEffect(() => {
    if (isLoggedIn) {
      const interceptor = axiosInstance.interceptors.request.use((config) => {
        return {
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${keycloak.token}` } as unknown as AxiosRequestHeaders
        };
      });

      return () => {
        axiosInstance.interceptors.request.eject(interceptor);
      };
    }
  }, [isLoggedIn, keycloak.token]);

  return <AxiosContext.Provider value={axiosInstance}>{children}</AxiosContext.Provider>;
}

import React from 'react';
import { DropdownArrow } from 'components/DropdownArrow';
import { DropdownList, DropdownListItem } from 'components/DropdownList';
import { useDropdown } from 'hooks/useDropdown';
import { ReactComponent as AvatarIcon } from './avatar.svg';
import s from './UserProfile.module.scss';
import { useKeycloak } from 'store/keycloak';

export function UserProfile() {
  const { showDropdown, setDropdownElement, handleDropdownToggle } = useDropdown();
  const { client } = useKeycloak();

  return (
    <div className={s.UserProfile__wrapper} ref={setDropdownElement}>
      <div className={s.UserProfile} onClick={handleDropdownToggle}>
        <div className={s.UserProfile__avatar}>
          <AvatarIcon />
        </div>
        <DropdownArrow isOpen={showDropdown} />
      </div>

      {showDropdown && (
        <DropdownList className={s.UserProfile__dropdown} style={{ minWidth: 'auto' }}>
          <DropdownListItem onClick={() => client.logout()}>Выйти</DropdownListItem>
        </DropdownList>
      )}
    </div>
  );
}

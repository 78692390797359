import React from 'react';
import { Button, ButtonLink } from 'components/Button';
import { Plug, PlugVariant } from 'components/Plug';
import { useKeycloak } from 'store/keycloak';
import s from './DataError.module.scss';

export function DataError() {
  const { client } = useKeycloak();
  return (
    <div className={s.DataError}>
      <Plug
        title={'Нет данных об организациях'}
        description={'Попробуйте обновить страницу или зайдите через некоторое время'}
        variant={PlugVariant.Error}
      />

      <div className={s.DataError__actions}>
        <ButtonLink className={s.DataError__action} onClick={() => client.logout()}>
          Выйти
        </ButtonLink>
        <Button className={s.DataError__action} onClick={() => window.location.reload()}>
          Обновить
        </Button>
      </div>
    </div>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import { ContentBox } from 'components/ContentBox';
import { UserProfile } from '../UserProfile';
import s from './TopBar.module.scss';

export function TopBar() {
  return (
    <nav className={s.TopBar}>
      <ContentBox className={s.TopBar__content}>
        <Link to={'/'} className={s.TopBar__logo}>
          DataHub
        </Link>
        <UserProfile />
      </ContentBox>
    </nav>
  );
}

import React from 'react';
import { Outlet } from 'react-router-dom';
import { ContentBox } from 'components/ContentBox';
import { TopBar } from './TopBar';
import s from './UserLayout.module.scss';

export function UserLayout() {
  return (
    <div className={s.UserLayout}>
      <TopBar />
      <ContentBox className={s.UserLayout__content}>
        <Outlet />
      </ContentBox>
    </div>
  );
}

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { AppRoutes } from 'routes';

const appTitle = 'datahub-react';
export default function App() {
  return (
    <React.Fragment>
      <Helmet defaultTitle={appTitle} titleTemplate={`%s — ${appTitle}`} />
      <AppRoutes />
    </React.Fragment>
  );
}

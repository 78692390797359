import { createContext } from 'react';
import Keycloak from 'keycloak-js';

export interface IKeycloakContext {
  client: Keycloak;
  isLoggedIn: boolean;
  error: string | null;
}

export const KeycloakContext = createContext<IKeycloakContext | null>(null);

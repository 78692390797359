import { useCallback, useState } from 'react';
import { useClickOutside } from './useClickOutside';

export function useDropdown() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownElement, setDropdownElement] = useState<HTMLDivElement | null>(null);

  const handleDropdownClose = useCallback(() => setShowDropdown(false), []);
  const handleDropdownToggle = useCallback(() => setShowDropdown((showDropdown) => !showDropdown), []);

  useClickOutside(dropdownElement, handleDropdownClose);

  return { showDropdown, setDropdownElement, handleDropdownClose, handleDropdownToggle };
}

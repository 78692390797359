import React from 'react';
import { Divider } from 'components/Divider';
import s from './FormError.module.scss';

export function FormError({ message }: { message: string }) {
  const lines = message.split('\n');
  const blockCount = lines.filter((m) => !m).length;

  return (
    <div className={s.FormError}>
      <p>Не удалось отправить заявку, подробнее{!!blockCount ? ` (${blockCount})` : ''}:</p>
      <p className={s.FormError__message}>
        {lines.map((m, i, ms) =>
          !!m ? (
            <span key={`${i} – ${m}`} style={{ paddingLeft: !ms[i - 1] ? 0 : 8 }}>{`${m}`}</span>
          ) : (
            <Divider className={s.FormError__divider} key={`${i} – ${m}`} />
          )
        )}
      </p>
    </div>
  );
}

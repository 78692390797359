import React from 'react';
import clsx from 'clsx';
import { ArrowDown } from 'components/Icon';
import s from './DropdownArrow.module.scss';

export interface DropdownArrowProps {
  isOpen?: boolean;
}

export function DropdownArrow({ isOpen }: DropdownArrowProps) {
  return <ArrowDown className={clsx(s.DropdownArrow, isOpen && s.DropdownArrow_isOpen)} />;
}

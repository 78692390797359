import React from 'react';
import { XlsxSizeS, XlsxSizeM } from '.';

export type IconType = {
  S: React.ElementType;
  M: React.ElementType;
};

export type FileIconMapperType = Record<string, IconType>;

export const FileIconMapper: FileIconMapperType = {
  xlsx: { S: XlsxSizeS, M: XlsxSizeM }
};

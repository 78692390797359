import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { UserLayout } from './_layouts/UserLayout';
import { MainPage } from './main';
import { TokenPage } from './token';

export function AppRoutes() {
  return (
    <Routes>
      <Route element={<UserLayout />}>
        <Route path={'/'} element={<MainPage />} />
        <Route path={'/token'} element={<TokenPage />} />
      </Route>

      {/* not found */}
      <Route path={'*'} element={<Navigate to={'/'} replace />} />
    </Routes>
  );
}

import React from 'react';
import { useKeycloak } from 'store/keycloak';
import s from './TokenPage.module.scss';

export function TokenPage() {
  const { client } = useKeycloak();
  return (
    <main className={s.TokenPage}>
      <h2 className={s.TokenPage__title}>Keycloak Token:</h2>
      {client.token}
    </main>
  );
}

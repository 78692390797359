import React, { useEffect, useMemo, useState } from 'react';
import { KeycloakContext } from './KeycloakContext';
import { keycloak } from './keycloak';

export function KeycloakProvider({ children }: { children: React.ReactNode }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const target = localStorage.getItem('target');
    if (!target) localStorage.setItem('target', document.location.pathname);
    keycloak
      .init({
        onLoad: 'login-required',
        checkLoginIframe: false,
        redirectUri: `${document.location.origin}/`
      })
      .then((isLoggedIn) => {
        window.history.replaceState(null, '', target || '/');
        localStorage.removeItem('target');
        setIsLoggedIn(isLoggedIn);
      })
      .catch((err) => setError(String(err)));
  }, []);

  const value = useMemo(
    () => ({
      client: keycloak,
      isLoggedIn,
      error
    }),
    [isLoggedIn, error]
  );

  return <KeycloakContext.Provider value={value}>{isLoggedIn && children}</KeycloakContext.Provider>;
}
